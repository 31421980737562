<template>
  <router-view/>
</template>

<style lang="scss">
body{
  padding: 0;
  margin: 0;
}
#app{
  padding: 0;
  margin: 0;
}
a{
  text-decoration:none;
}
</style>
