import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/index/index.vue'
import mobile from '../views/mobile/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: index
  },
  {
    path: '/Mobile',
    name: 'mobileHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: mobile
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let fail = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  console.log(fail)
  if(fail){
    if(to.fullPath==='/Mobile'){
      next();
    }else {
      next({
        path: '/Mobile'
      })
    }
  } else {
    if(to.fullPath.indexOf('/Mobile')!==-1){
      next({
        path: '/'
      })
    }else {
      next()
    }
  }
})
export default router
